html {
    max-width: 100%;
}

body {
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    max-width: 100%;
}

img {
    display: block;
    margin: 0;
    padding: 0;
}

.header {
    display: flex;
    flex-direction: column;
    height: 120px;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border-bottom: 3px solid #ccc;
    border-top: 6px solid #61dafb;

}

.center {
    width: 94%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    place-items: center;
}


.logo {
    display: flex;
    place-items: center;
}

.logo img {
    height: 140px;
    animation: logo_development infinite 10s linear;
}

@keyframes logo_development {
    0% {
        transform: rotate(0deg);
        transform: scale(80%);
    }
    25% {
        transform: rotate(35deg);
    }
    50%{
        transform: rotate(0deg);
        transform: scale(80%);
    }
    75%{
        transform: rotate(-35deg);
    }
    100%{
        transform: rotate(0deg);
        transform: scale(80%);
    }
}

.brand {
    display: block;
    margin: 0;
}


.menu {
    display: flex;
}

.menu ul {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.menu ul li {
    list-style: none;
}

.menu ul li a {
    text-decoration: none;
    color: #444;
    transition: 300ms color;
}

.menu ul li a:hover{
    color: #38a7c5;
}

.slider {
    display: flex;
    flex-direction: column;
    place-content: center;
    gap: 1rem;
    width: 100%;
    background: rgb(99,229,255);
    background: linear-gradient(190deg, rgba(99,229,255,1) 0%, rgba(2,101,191,1) 100%);
    height: 250px;
    /* line-height: 150px; */
    
}

.slider h1 {
    color: #fff;
    text-shadow: 0px 0px 10px rgb(46, 46, 46);
    margin: 2px;
    padding: 0;
}

.btn-white {
    display: block;
    background-color: #fff;
    color: #000;
    width: 95px;
    height: 30px;
    margin: 0 auto;
    padding: 10px;
    border-radius:15px;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    line-height:30px;
    box-shadow: 0 0 5px #444;
    transition: color background-color 3s;
}

.btn-white:hover {
    background-color: #444;
    color: #fff;
}

.slider-small {
    height: 100px;
}

.slider-small h1 {
    text-align: left;
    margin-left: 50px;
}

.main {
    align-items:unset;
}

.content {
    width: 70%;
    min-height: 450px;
}

.articles{
    display: flex;
    flex-direction: column;
}

.article-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left;
}

.article-img-wrap {
    width: 130px;
    height: 130px;
    overflow: hidden;
    margin-right: 15px;
    text-align: center;

}

.article_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.article_content h2 {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
}

.subheader {
    font-size: 38px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

.article-date {
    display: block;
    width: 100%;
    color: rgb(127, 127, 127);
}

.article-img-wrap img {
    height: 90%;
    text-align: center;
}

.article-item a {
    display: block;
    color: #444;
    text-decoration: none;
    background-color: transparent;
}

.article-item a:hover {
    text-decoration:underline;
}

.sidebar {
    width: 25%;
}


.sidebar-item {
    background: #f7f7f7;
    padding: 20px;
    margin-top: 30px;
}

.sidebar-item h3 {
    text-transform: uppercase;
    font-size: 18px;
    margin: 0;
    padding-bottom: 10px;
    margin-bottom:10px;
    border-bottom: 3px solid #eee;
}

.article_detail {
    display: flex;
    flex-direction: column;
}

.article_detail .article-img-wrap {
    width: 100%;
    height: 450px;
    margin: 0 auto;
}

.article_detail .article_content {
    
}

.article_detail .article_content p {
    text-align: justify;
}


.btn {
    display: block;
    background: rgb(0, 0, 0);
    color: #fff;
    width: 150px;
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border-radius: 12px
}

.btn:active {
    transform: scale(90%);
}

.sidebar-item .btn {
    margin: 2px auto;
}

.btn-success {
    background: rgb(23, 150, 19);
    margin: 20px 0;
}

.btn-submit {
    margin: 20px auto;
    background: rgb(4, 41, 70);
}

.btn-success:hover, .btn-submit:hover  {
    background: rgb(4, 112, 195);
}

.article-btns {
    display: flex;
    gap: 1.5em;
}

.btn-delete {
    background: rgb(233,2,23);
    border: none;
}

.btn-delete:hover {
    background: rgb(98, 0, 8);
}

.btn-update{
    background: rgb(133, 210, 152) !important;
    border: none;
    list-style: none;    
}

.btn-update:hover{
    background: rgb(28, 186, 68) !important;
}

input[type="text"], textarea{
    width: 100%;
    min-height: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

input[type="file"] {
    width: 100%;
    min-height: 30px;
    border: 1px solid #ccc;
    padding: 35px 5px;
    margin-bottom: 15px;
    background:rgb(205, 233, 198);
}

input[type="file"]::before {
    content: "Arrastra y suelta:";
    margin: auto;
    padding: auto;
    text-align: center;
    
}

input[type="text"]:focus {
    box-shadow: 0px 0px 5px #444 inset;
}

textarea {
    width: 100%;
    height: 200px;
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 300px;

}

.mid-form {
    width: 60%;
    margin: 0px auto;
}

.mid-form label {
    display: block;
    text-align:left;
    margin: 10px 0 5px 0;
    font-size: 17px;
}

.radiobuttons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: 10px auto;
}


.footer {
    display: flex;
    place-content: center;
    place-items: center;
    background-color: #f7f7f7;
    width: 100%;
    height: 70px;
    margin: 0;
    padding: 0;
}


.footer p {
    font-size: 14px;
    margin: 0;
    margin: 0 auto;
    font-family: cursive;
    padding: 0;
}



/* Responsive */

@media (max-width: 1448px) {
    /* Responsive */
    
    .center {
        
    }

}

@media (max-width: 963px) {
    /* Responsive */
    
    .center {
        
    }

}
